<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      v-if="
        hasAction('view_user') || isAdmin() || isSuperAdmin() || isModerator() || isAccountant()
      "
      wrap
    >
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="questionSearch.searchText"
                    :label="$t('common.search')"
                    clearable
                    @keydown.enter="onSearch($event)"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch($event)"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('questionAndAnswer.title')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              v-if="
                hasAction('create_user') ||
                  isAdmin() ||
                  isSuperAdmin() ||
                  isAccountant()
              "
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddQuestion(null)"
            >
              {{ $t("common.add") }}
            </v-btn>
          </template>
          <table class="table question-table">
            <thead>
              <tr>
                <th
                  v-for="(header, index) in questionHeaders"
                  :key="header + index"
                  scope="col"
                >
                  {{ $t(header.text) }}
                </th>
              </tr>
            </thead>
            <draggable
              v-model="questionList"
              v-bind="dragOptions"
              tag="tbody"
              @update="updateShort"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <tr
                v-for="(item, index) in questionList"
                :key="item + index">
                <td>{{ item.stt }}</td>
                <td
                  class="cursor-pointer"
                  @mouseover="onHoverEmail(item)"
                  @mouseleave="onLeaveEmail(item)"
                >
                  <span
                    class="pb-1"
                    style="border-bottom: 1px dashed #5cb860;"
                  >{{ item.questionContent }}
                  </span>
                  <div
                    v-show="item.isShowTool"
                    class="mt-1"
                    style="display: flex;"
                  >
                    <span
                      class="text-success hover-detail-text"
                      style="white-space: nowrap;"
                      @click="onShowModalDetailQuestion(item)"
                    >{{ $t("users.viewDetail") }}</span
                    >
                    <span
                      v-if="
                        (hasAction('delete_user') &&
                          item.id != GET_CURRENT_ACCOUNT.id) ||
                          isAdmin() ||
                          isSuperAdmin() ||
                          isAccountant()
                      "
                      class="mx-2"
                      style="white-space: nowrap;"
                    >|</span
                    >
                    <span
                      v-if="
                        (hasAction('delete_user') &&
                          item.id != GET_CURRENT_ACCOUNT.id) ||
                          isAdmin() ||
                          isSuperAdmin() ||
                          isAccountant()
                      "
                      class="hover-delete-text"
                      style="color: red;"
                      @click="onShowModalDeleteQuestion(item.id)"
                    >{{ $t("common.delete") }}</span
                    >
                  </div>
                </td>
                <td style="width: 30%; white-space: pre-wrap;">{{ item.questionAnswer }}</td>
                <td>
                  <!-- <v-tooltip
                    v-if="isAdmin() || isSuperAdmin() || isModerator()"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isLoading"
                        class="mx-2"
                        fab
                        small
                        color="success"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalDeleteQuestion(item)">
                        <v-icon dark>mdi-cursor-move</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('users.addPoints') }}</span>
                  </v-tooltip> -->
                  <v-menu
                    bottom
                    left
                    offset-y
                    transition="slide-y-transition">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="item.isLoading"
                        class="mx-2"
                        fab
                        small
                        color="success"
                        style="color: #ffffff;"
                        v-on="on"
                      >
                        <v-icon dark>mdi-cursor-move</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-list dense>
                        <v-list-tile
                          v-for="(action, index) in moveActionList"
                          :key="action + index"
                          @click="clickMoveQuestion(action, item)"
                        >
                          <v-list-tile-title v-text="$t(action.text)" />
                        </v-list-tile>
                      </v-list>
                    </v-card>
                  </v-menu>
                </td>
              </tr>
            </draggable>
          </table>
        </material-card>
      </v-flex>
    </v-layout>
    <pagination
      :total="questionPaginate.totalPage"
      :current-page="questionPaginate.currentPage"
      :row-per-page="questionPaginate.rowPerPage"
      @onPageChange="onPageChange"
    />
    <questionDetail
      ref="QuestionDetail"
      @getQuestionList="getQuestion()" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <!-- confirm delete user -->
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteQuestion()"
    />
    <!-- <userDetailModal ref="userDetailModal" /> -->
    <loadingBar :is-loading="isLoading" />
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import QuestionDetail from './QuestionDetail'
import Avatar from 'vue-avatar'
import ConfirmModal from 'Components/ConfirmModal'
import RoleType from 'enum/roleType'
import draggable from 'vuedraggable'
export default {
  components: {
    questionDetail: QuestionDetail,
    Avatar,
    ConfirmModal,
    draggable
  },
  data: () => ({
    questionHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'questionAndAnswer.questionContent',
        value: 'questionContent'
      },
      {
        sortable: false,
        text: 'questionAndAnswer.questionAnswer',
        value: 'questionAnswer'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    questionList: [],
    questionListClone: [],
    questionPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    questionSelected: null,
    menu: false,
    isShowUserDetail: true,
    confirmModalTitle: '',
    questionSearch: {
      searchText: null
    },
    isLoading: false,
    moveActionList: [
      {
        value: 1,
        text: 'questionAndAnswer.orderPriority.moveToFirst',
        action: 1
      },
      { value: 2, text: 'questionAndAnswer.orderPriority.moveToUp', action: 3 },
      {
        value: 3,
        text: 'questionAndAnswer.orderPriority.moveToDown',
        action: 4
      },
      {
        value: 4,
        text: 'questionAndAnswer.orderPriority.moveToLast',
        action: 2
      }
    ],
    actionOrderPriority: null,
    spliceStringValue: 150
  }),
  computed: {
    dragOptions () {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    ...mapGetters([
      'GET_SNACK_BAR',
      'GET_QUESTION_AND_ANSWER_DATA',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    GET_QUESTION_AND_ANSWER_DATA () {
      this.questionPaginate.currentPage = this.GET_QUESTION_AND_ANSWER_DATA.paginate.currentPage
      this.questionPaginate.totalPage = this.GET_QUESTION_AND_ANSWER_DATA.paginate.totalPage
      this.questionPaginate.rowPerPage = this.GET_QUESTION_AND_ANSWER_DATA.paginate.perPage
      let questionList = this.GET_QUESTION_AND_ANSWER_DATA.results
      this.questionList = []
      this.questionListClone = []
      for (let i = 0, size = questionList.length; i < size; i++) {
        let questionObj = {
          stt:
            i +
            1 +
            (this.GET_QUESTION_AND_ANSWER_DATA.paginate.currentPage - 1) *
              this.GET_QUESTION_AND_ANSWER_DATA.paginate.pageSize,
          // stt: i + 1,
          id: questionList[i].id,
          questionContent: questionList[i].content,
          questionAnswer: questionList[i].answer.length > this.spliceStringValue
            ? functionUtils.spliceString(questionList[i].answer, this.spliceStringValue) : questionList[i].answer,
          priority: questionList[i].priority,
          isShowTool: false
        }
        this.questionList.push(questionObj)
        this.questionListClone.push({ ...questionObj })
      }
      this.isLoading = false
    }
  },
  created () {
    this.getQuestion()
  },
  methods: {
    /**
     * Hover email
     */
    onHoverEmail: function (userItem) {
      userItem.isShowTool = true
    },
    /**
     * Leave email
     */
    onLeaveEmail: function (userItem) {
      userItem.isShowTool = false
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(
        RoleType.ADMIN,
        this.GET_CURRENT_ACCOUNT
      )
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(
        RoleType.MODERATOR,
        this.GET_CURRENT_ACCOUNT
      )
    },
    /**
     * Search
     */
    onSearch: function (event) {
      event.preventDefault()
      this.getQuestion()
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Show modal add user
     */
    onShowModalAddQuestion: function () {
      this.$refs.QuestionDetail.onAddNew(this.questionPaginate.currentPage)
    },
    onShowModalDetailQuestion: function (data) {
      this.$refs.QuestionDetail.onShowModal(
        data,
        this.questionPaginate.currentPage
      )
    },
    /**
     * Show modal delete user
     */
    onShowModalDeleteQuestion: function (questionId) {
      this.questionSelected = questionId
      this.confirmModalTitle = this.$t(
        'questionAndAnswer.confirmDeleteQuestion'
      )
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Delete user
     */
    onDeleteQuestion: function () {
      this.DELETE_QUESTION_AND_ANSWER({ id: this.questionSelected }).then(
        function () {
          this.questionSelected = null
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.getQuestion()
        }.bind(this)
      )
    },
    /**
     * Get date of birth
     */
    getDateOfBirth: function (dob) {
      if (!functionUtils.isNull(dob)) {
        return dateUtils.formatDate(
          dob,
          dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
          dateUtils.STATIC_FORMAT_DATE
        )
      }
      return null
    },
    /**
     * Get user list
     */
    getQuestion: function () {
      this.isLoading = true
      let filter = {
        params: {
          page: this.questionPaginate.currentPage,
          searchText: this.questionSearch.searchText
        }
      }
      this.GET_QUESTION_AND_ANSWER(filter)
        .then(function () {})
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.questionPaginate.currentPage = page
      this.getQuestion()
    },
    updateShort (evt) {
      this.reorder(evt.oldIndex, evt.newIndex)
      this.updateQuestionPriorityCustom()
    },
    reorder (oldIndex, newIndex) {
      // move the item in the underlying array
      // update order property based on position in array
      this.questionList.splice(oldIndex, 0, this.questionList.splice(oldIndex, 1)[0])
      this.questionList.forEach((item, index) => {
        item.stt = this.questionListClone[index].stt
        item.priority = this.questionListClone[index].priority
      })
    },
    clickMoveQuestion: function (actionValue, item) {
      this.actionOrderPriority = actionValue.action
      this.updateQuestionPriority(actionValue, item)
    },
    updateQuestionPriority: function (actionValue, item) {
      let data = {
        first_question_id: item.id,
        action_type: actionValue.action
      }
      this.UPDATE_PRIORITY_QUESTION_AND_ANSWER(data)
        .then(
          function () {
            this.getQuestion()
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    updateQuestionPriorityCustom: function () {
      let data = {
        question_list: this.questionList,
        action_type: 5
      }
      this.UPDATE_PRIORITY_QUESTION_AND_ANSWER(data)
        .then(
          function () {
            this.getQuestion()
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    ...mapActions([
      'GET_QUESTION_AND_ANSWER',
      'UPDATE_USER_PROFILE',
      'DELETE_QUESTION_AND_ANSWER',
      'UPDATE_PRIORITY_QUESTION_AND_ANSWER',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>
<style lang="scss" scoped>
@import "../../styles/material-dashboard/_colors.scss";
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
// /deep/ .theme--light.v-list {
//   background-color: transparent;
// }
.border-style {
  border-bottom: 2px solid $border-color;
}
.cursor-pointer {
  cursor: pointer !important;
}
.hover-detail-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-delete-text:hover {
  border-bottom: 1px solid $border-color;
}
.hover-active-text:hover {
  border-bottom: 1px solid $border-color;
}
.question-table thead tr {
  color: #0000008a;
}
.question-table tbody tr:hover {
  background-color: #c7c7c7c7 !important;
}
.v-menu__content .v_card .v_list {
  background: red;
}
.v-menu__content .v_card {
  background: red;
  padding: 50px;
}
</style>
