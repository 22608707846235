<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="
          questionId === 0
            ? $t('questionAndAnswer.addQuestion')
            : $t('questionAndAnswer.detailQuestion')
        "
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm8
                md12>
                <v-text-field
                  :rules="[requiredValue]"
                  v-model="questionContent"
                  :label="$t('questionAndAnswer.questionContent')"
                  :name="$t('questionAndAnswer.questionContent')"
                  prepend-icon="mdi-chat-question-outline"
                  type="text"
                  class="required"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex
                xs12
                sm8
                md12>
                <v-textarea
                  :rules="[requiredValue]"
                  v-model="questionAnswer"
                  :label="$t('questionAndAnswer.questionAnswer')"
                  prepend-icon="mdi-forum"
                  class="required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-if="isAdmin() ||
            isSuperAdmin() ||
            isAccountant()
          "
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import functionUtils from 'utils/functionUtils'
import ToastType from 'enum/toastType'
import RoleType from 'enum/roleType'
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      valid: false,
      isShowModal: false,
      questionId: 0,
      questionContent: null,
      questionAnswer: null,
      menu: false,
      currentPage: 1,
      points: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['GET_CURRENT_ACCOUNT'])
  },
  created () {},
  methods: {
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(
        RoleType.ADMIN,
        this.GET_CURRENT_ACCOUNT
      )
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    validateStringIsNull: function (value) {
      return functionUtils.validateStringIsNull(value)
    },
    /**
     * Required value
     */
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    onAddNew: function (currentPage) {
      this.questionId = 0
      this.isShowModal = true
      this.questionContent = null
      this.questionAnswer = null
      this.currentPage = currentPage
    },
    onShowModal: function (questionData, currentPage) {
      this.isShowModal = true
      this.questionId = questionData.id
      this.questionContent = questionData.questionContent
      this.questionAnswer = questionData.questionAnswer
      this.currentPage = currentPage
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let data = {
          id: this.questionId,
          content: this.questionContent,
          answer: this.questionAnswer
        }
        if (this.questionId === 0) {
          this.CREATE_QUESTION_AND_ANSWER(data)
            .then(
              function () {
                this.isShowModal = false
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.$emit('getQuestionList', true)
              }.bind(this)
            )
            .catch(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.somethingWentWrong'),
                  styleType: ToastType.ERROR
                })
              }.bind(this)
            )
        } else {
          this.UPDATE_QUESTION_AND_ANSWER(data)
            .then(
              function () {
                this.isShowModal = false
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.$emit('getQuestionList', true)
              }.bind(this)
            )
            .catch(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.somethingWentWrong'),
                  styleType: ToastType.ERROR
                })
              }.bind(this)
            )
        }
      }
    },
    ...mapActions([
      'CREATE_QUESTION_AND_ANSWER',
      'ON_SHOW_TOAST',
      'UPDATE_QUESTION_AND_ANSWER'
    ])
  }
}
</script>
